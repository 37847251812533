import "./singlepost.css";
import { useLocation } from "react-router";
import { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../config";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Newsletter from "../newsletter/Newsletter";
import facebook from "../../icons/facebook.svg";
import linkedin from "../../icons/linkedin.svg";
import twitterx from "../../icons/twitterx.svg";
import whatsapp from "../../icons/whatsapp.svg";

import { Helmet } from "react-helmet";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  // const PF = "/images/";
  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [updateMode, setUpdateMode] = useState(false);

  // Create a function to open a share link for Facebook
  const shareToFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(facebookShareUrl, "_blank");
  };
  const LinkedinShareButton = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite?url=${window.location.href}`;
    window.open(linkedinShareUrl, "_blank");
  };

  // Create a function to open a share link for Twitter
  const shareToTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${window.location.href}`;
    window.open(twitterShareUrl, "_blank");
  };

  // Create a function to open a share link for WhatsApp
  const shareToWhatsApp = () => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  useEffect(() => {
    const getPost = async () => {
      const res = await axiosInstance.get("/posts/" + path);
      setPost(res.data);
      setTitle(res.data.title);
      setDesc(res.data.desc);
    };
    getPost();
  }, [path]);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/posts/${post._id}`, {
        data: { username: user.username },
      });
      window.location.replace("/");
    } catch (err) {}
  };

  const handleUpdate = async () => {
    try {
      await axiosInstance.put(`/posts/${post._id}`, {
        username: user.username,
        title,
        desc,
      });
      setUpdateMode(false);
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="Blog des étudiants - université de Ngozi" content={title} />
      </Helmet>

      <div className="singlepost">
        <div className="singlePostWrapper">
          {post.photo && (
            <img src={post.photo} alt="" className="singlePostImg" />
          )}
          {updateMode ? (
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="singlePostTitleInput"
              autoFocus
            ></input>
          ) : (
            <h1 className="singlePostTitle">
              {title}
              {post.username === user?.username && (
                <div className="singlePostEdit">
                  <i
                    className="singlePostIcon far fa-edit"
                    onClick={() => setUpdateMode(true)}
                  ></i>
                  <i
                    class="singlePostIcon far fa-trash-alt"
                    onClick={handleDelete}
                  ></i>
                </div>
              )}
            </h1>
          )}

          <div className="share-buttons">
            <label>Share:</label>
            <button onClick={shareToFacebook}>
              <img src={facebook} alt="" className="share" />
            </button>
            <button onClick={shareToTwitter}>
              <img src={twitterx} alt="" className="share" />
            </button>
            <button onClick={shareToWhatsApp}>
              <img src={whatsapp} alt="" className="share" />
            </button>
            <button onClick={LinkedinShareButton}>
              <img src={linkedin} alt="" className="share" />
            </button>
          </div>

          <div className="singlePostInfo">
            <span className="singlePostAuthor">
              Author:
              <Link to={`/?user=${post.username}`} className="link">
                <b>{post.username}</b>
              </Link>
            </span>
            <Link to={`/?categories=${post.categories}`} className="link">
              <span>Category:{post.categories}</span>
            </Link>
            <span className="singlePostDate">
              {new Date(post.createdAt).toDateString()}
            </span>
          </div>
          {updateMode ? (
            <ReactQuill
              theme="snow"
              value={desc}
              onChange={setDesc}
              placeholder="Storytelling section..."
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  ["link"],
                ],
              }}
            />
          ) : (
            <p className="singlePostDesc">
              {<div dangerouslySetInnerHTML={{ __html: `${desc}` }}></div>}
            </p>
          )}
          {updateMode && (
            <button className="singlePostButton" onClick={handleUpdate}>
              Update
            </button>
          )}
        </div>

        <Newsletter />
      </div>
    </>
  );
}

import './newsletter.css'
import React, { useState } from 'react';
import { axiosInstance } from '../../config';


function Newsletter() {
  const [email, setEmail] = useState('');
  const [error, setError]=useState(false);
  const [sucess, setSucess]=useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const newLetter ={
        email:email,
      };
      try{
        await axiosInstance.post("/newsletters", newLetter);
        setSucess(true);

    }catch(error){
        setError(true);
    }
    
      
    setEmail(''); // Clear the email input field after submission
  };
 

  return (
    <div className="newsletter-container">
      <h2>Subscribe to Our Newsletter</h2>
      <p>Stay updated with the latest weekly news and updates.</p>
      <form onSubmit={handleSubmit} className="newsletter-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit" className='buttonNewsletter'>Subscribe</button>
        {sucess &&<span  style={{color:'green'}}>Sucessfully Submitted</span>}
        {error && <span  style={{color:'red'}}>Already registred</span>}
      </form>
      
    </div>
  );
}

export default Newsletter;

// import { Link } from 'react-router-dom'
import './login.css'
import { useRef, useState } from 'react'
import { Context } from '../../../context/Context';
import { useContext } from 'react';
import { axiosInstance } from '../../../config';

export default function Login() {

  const userRef =useRef();
  const passwordRef =useRef();
  const {dispatch, isFetching}=useContext(Context);
  const [error, setError]=useState(false);

  const handleSubmit =async  (e)=>{
    e.preventDefault();
    dispatch({type:"LOGIN_START"});
    try{
      const res= await axiosInstance.post("/auth/login",{
        email:userRef.current.value,
        password:passwordRef.current.value,
      })
      dispatch({type:"LOGIN_SUCCESS", payload:res.data})
    } catch(err){
      setError(true)
    }
  }
  


  return (
    <div className="login">
        <span className="loginTitle" style={{fontSize:"20px", fontWeight:'90px'}}> <b>Login</b></span>
        <form  className="loginForm" onSubmit={handleSubmit}>
            <label>Email</label>
            <input type="email" 
             placeholder='enter your username...'
             ref={userRef}
              className='loginInput' 
              />
            <label>Password</label>
            <input type="password" 
            placeholder='enter your password...'
             className='loginInput'
             ref={passwordRef}/>
            <button className="loginButton" 
            type='submit'
            disabled={isFetching}
            >Login</button> 
        </form>
        <span style={{color:"red", marginTop:"10px"}}>{error && <span>Wrong credentials, refresh the page and try again!</span>}</span>
    </div>
  )
}

import './singleAdminPost.css'
import{useLocation} from "react-router"
import { useContext, useEffect, useState } from 'react';
import { axiosInstance } from '../../../config';
import { Link } from 'react-router-dom';
import { Context1 } from '../../../context/Context1';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import facebook from '../../../icons/facebook.svg'
import linkedin from '../../../icons/linkedin.svg' 
import twitterx from '../../../icons/twitterx.svg'
import whatsapp from '../../../icons/whatsapp.svg'


import {Helmet}  from 'react-helmet'



export default function SingleAdminPost() {

    const location =useLocation();
    const path = location.pathname.split("/")[2];
    const [postAdmin, setPostAdmin]=useState({});
    const {admin}=useContext(Context1);
    const [title, setTitle]=useState('');
    const [desc, setDesc]=useState('');
    const [updateMode, setUpdateMode]=useState(false);
    
    
    // Create a function to open a share link for Facebook
  const shareToFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(facebookShareUrl, '_blank');
  };

  // Create a function to open a share link for Twitter
  const shareToTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${window.location.href}`;
    window.open(twitterShareUrl, '_blank');
  };

  // Create a function to open a share link for WhatsApp
  const shareToWhatsApp = () => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(whatsappShareUrl, '_blank');
  };
  const LinkedinShareButton = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite?url=${window.location.href}`;
    window.open(linkedinShareUrl, '_blank')}

    useEffect(()=>{
      const getPostAdmin = async () =>{
        const res= await axiosInstance.get("/postsadmins/"+path);
        setPostAdmin(res.data);
        setTitle(res.data.title);
        setDesc(res.data.desc);
      };
      getPostAdmin()
    }, [path])

    const handleDelete=async()=>{
      try{
        await axiosInstance.delete(`/postsadmins/${postAdmin._id}`, {data:{admin:admin}});
      window.location.replace('/');
      } catch(err){ }
      
    }

    const handleUpdate = async ()=>{
      try{
        await axiosInstance.put(`/postsadmins/${postAdmin._id}`, 
        {admin:admin.admin,
        title,
      desc});
      setUpdateMode(false);
      } catch(err){ }
    }
   
  return (
    <>
      <Helmet>
    <meta charSet='utf-8'/>
    <title>{title}</title>
    <meta name="description" content="This is the home page of my website." />
   </Helmet>



    <div className="singlepost">
        <div className="singlePostWrapper">
          {postAdmin.photo && 
                <img src={postAdmin.photo} alt="" className="singlePostImg" />}
          {updateMode ? 
          <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)}
          className="singlePostTitleInput"
          autoFocus></input>:
                
            (<h1 className="singlePostTitle">
                {title} 
                {postAdmin.admin===admin?.admin &&(
                  <div className="singlePostEdit">
                  <i className="singlePostIcon far fa-edit" onClick={()=>setUpdateMode(true)}></i>  
                  <i class="singlePostIcon far fa-trash-alt" onClick={handleDelete}></i>  
                  </div>
                )}
            </h1>) }

    <div className="share-buttons">
    <label>Share:</label>
          <button onClick={shareToFacebook}>
            <img src={facebook} alt="" className='share'/>
          </button>
          <button onClick={shareToTwitter}>
            <img src={twitterx} alt="" className='share' />
          </button>
          <button onClick={shareToWhatsApp}>
            <img src={whatsapp} alt="" className='share' />
          </button>
          <button onClick={LinkedinShareButton}>
            <img src={linkedin} alt="" className='share' />
          </button>
        </div>
        
            <div className="singlePostInfo">
                <span className="singlePostAuthor">Author:
                <Link to={`/?user=${postAdmin.admin}`}  className='link'>
                  <b>{postAdmin.admin}</b>
                  </Link></span>
                  <Link to={`/?categories=${postAdmin.categories}`}  className='link'>
                    <span>Category:{postAdmin.categories}</span></Link>
                <span className="singlePostDate">{new Date(postAdmin.createdAt).toDateString()}</span>
                </div>   
                {updateMode ? 
                <ReactQuill 
                theme="snow" 
                value={desc}
                onChange={setDesc}
                placeholder="Storytelling section..."
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    ['link'],
                  ],
                }}
              />
                 :
                (<p className='singlePostDesc'>{<div dangerouslySetInnerHTML=
                  {{ __html: `${desc}` }}></div>}</p>)}
                {updateMode && <button className="singlePostButton" onClick={handleUpdate}>Update</button>}
        </div>
        
        
    </div></>
  )
}




import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle `
  body,.top,.container, .sidebar, .articles-container , .topl, a {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all .5s linear;
  }

  .li {
    color: ${({ theme }) => theme.text};
    transition: all .5s linear;

  }
  
`;

export const lightTheme = {
  body: '##FFFAFA',
  text: '#1D2B3D',
  primary: '#6200ee',
};

export const darkTheme = {
  body: '#1D2B3D',
  text: '#FFFAFA',
  primary: '#bb86fc',
};

import { useState } from 'react'
import './registerAdmin.css'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../../config';

export default function Login() {
  
  const [admin, setadmin]=useState('');
  const [email, setEmail]=useState('');
  const [password, setPassword]=useState('');
  const [error, setError]=useState(null);

  const handleSubmit= async (e)=>{
    e.preventDefault();
    try{
    const res = await axiosInstance.post("/authAdmin/registerAdmin",
     {admin,
    email,
  password});
      res.data && window.location.replace('/1q2w3e4r');
    } catch(err){
      setError(true)
    }
  }
  

  return (
    <div className="register">
        <span className="registerTitle" style={{fontSize:"20px", fontWeight:'90px'}}> <b>Register</b></span>
        <form  className="registerForm" onSubmit={handleSubmit}>
            <label>admin</label>
            <input type="text" 
            placeholder='enter your admin...' 
            className='registerInput'
            onChange={e=>setadmin(e.target.value)} />
            <label>Email</label>
            <input type="text"
             placeholder='enter your email...' 
             className='registerInput'
             onChange={e=>setEmail(e.target.value)}/>
            <label>Password</label>
            <input type="password" 
            placeholder='enter your password...' 
            className='registerInput'
            onChange={e=>setPassword(e.target.value)}/>
            <button className="registerButton" type='Submit'>
              Register
            </button>
            <span style={{color:'red', marginTop:'10px'}}>
              {error && <span>admin or mail already used </span>}</span>
        </form>
        <button className="registerLoginButton"><Link to='/loginAdmin' className='link'>Login</Link></button>
    </div>
  )
}

import './topbar.css'
import {Link } from 'react-router-dom';
import { useContext,  useState  } from 'react';
import { Context } from '../../context/Context';
import { Context1} from '../../context/Context1';
import img from '../TopBar/download.png'
import menu from '../Topbars/TopBarClient/menu.png'


export default function TopBar() {
    const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleListItemClick = () => {
    setCheckboxChecked(false); // Disable the checkbox when a list item is clicked
  };
   const handleClick =()=>{
        window.scrollTo(400, 400);
    }


    

    const {user, dispatch} = useContext(Context);
    const { admin, dispatch1} =useContext(Context1);

    const handleLogout =()=>{
        dispatch({type:"LOGOUT"});
    }
    const handleLogout1 =()=>{
        dispatch1({type:"LOGOUTADMIN"});
    }
    

  return ( 
    <header className="top">
        <a href="/" className="logo">THE STUDENTS' BLOG</a>

            <input type="checkbox" id="menu-bar" checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />
            <label htmlFor="menu-bar"><img src={menu} alt="" style={{height:'30px'}}/></label>


            <nav className="navbar">
            <ul>
                <li> <Link to="/" className='link' onClick={handleListItemClick} >HOME</Link> </li>
                <li> <Link to="/about"  className='link' onClick={handleListItemClick}>ABOUT</Link> </li>
                  <li>
            <a
              href="https://bubuya-gpt.vercel.app/"
              target="_blank"
              rel="opener noreferrer"
            >
              AI
            </a>
          </li>
                <li> <Link to="/bloggers" onClick={handleListItemClick}> BLOGGERS</Link> </li> 
                <li> <Link to="/write" onClick={handleListItemClick}>{user && 'WRITE'}</Link> </li>
                <li onClick={handleLogout}><Link to="/">{user && 'LOGOUT'}</Link> </li>
                <li onClick={handleLogout1}><Link to="/">{admin && 'LOGOUT ADMIN'}</Link> </li>
                <div className="topRight">
            {user && <Link className='link' to='/settings'><img className='topImg'
             src={user.profilepic} alt="profile-icon" /></Link> } 
            {/* //  (
            //     <ul>
            //     <li ><Link to='/login' className='link' onClick={handleListItemClick}>LOGIN</Link></li>
            //     </ul>
            //  )}  */}
             {admin && <li ><Link to='/writeadmin' className='link'>WRITEADMIN</Link></li>}
             {admin && <li ><Link to='/register' className='link'>REGISTER</Link></li>}</div>    
            </ul>
        </nav>
        </header>
  )
}
  

import './settings.css';
import { useContext, useState } from 'react';
import { Context } from '../../../context/Context';
import { axiosInstance } from '../../../config';
import UserBio from '../userBio/UserBio';
import {storage} from "../../../Firebase";
import{ref,getDownloadURL,uploadBytes} from "firebase/storage"

import {Helmet}  from 'react-helmet'


export default function Settings() {
    const [file, setFile] = useState(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [biography, setBiography] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [web, setWeb] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const { user, dispatch } = useContext(Context);

    const handleUpdate = async (e) => {
        e.preventDefault();
        dispatch({ type: "UPDATE_START" });

        // Validate password length
        if (password.length < 6) {
            setError("Password must be at least 6 characters long.");
            dispatch({ type: "UPDATE_FAILURE" });
            return;
        }
        if (username.length < 6) {
          setError("username must be at least 6 characters long.");
          dispatch({ type: "UPDATE_FAILURE" });
          return;
      }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError("Invalid email format.");
            dispatch({ type: "UPDATE_FAILURE" });
            return;
        }

        const updatedUser = {
            userId: user._id,
            username,
            email,
            password,
            biography,
            facebook,
            twitter,
            web,
        };

        if (file) {
            const storageRef = ref(storage, `/profilePic/${file.name}`);
            await uploadBytes(storageRef, file);
            const imageUrl = await getDownloadURL(storageRef);
            updatedUser.profilepic = imageUrl;
        }

        try {
            const res = await axiosInstance.put("/users/" + user._id, updatedUser);
            setSuccess(true);
            dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
            window.location.replace('/');
        } catch (err) {
            setError("Update failed. Please check your input.");
            dispatch({ type: "UPDATE_FAILURE" });
        }
    };

    return (
        <>
            <Helmet>
    <meta charSet='utf-8'/>
    <title>{`${user.username}'s profile`}</title>
    <meta name="description" content="This is the home page of my website." />
   </Helmet>


        <div className="settings">
            <div className="settingsWrapper">
                <div className="settingsTitle">
                    <span className="settingsUpdateTitle">Update Your Account</span>
                </div>
                <form className="settingsForm" onSubmit={handleUpdate}>
                    <label>Profile Picture</label>
                    <div className="settingsPP">
                        <img src={file ? URL.createObjectURL(file):user.profilepic} alt="" />
                        <label htmlFor="fileInput">
                            <i className='settingsPPIcon far fa-user-circle'></i>
                        </label>
                        <input type="file" required
                        id='fileInput' 
                        onChange={(e)=>setFile(e.target.files[0])}
                        style={{display:"none"}} />
                    </div>
                    <label >Username</label>
                    <input type="text" placeholder={user.username} required
                    onChange={(e)=>setUsername(e.target.value)} />
                    <label >Email</label>
                    <input type="email" placeholder={user.email} required
                    onChange={(e)=>setEmail(e.target.value)}/>
                    <label >Password</label>
                    <input type="password"
                     onChange={(e)=>setPassword(e.target.value)} required />
                     <label >Your biography</label>
                     <textarea className='updateInput' placeholder={user.biography}
                     onChange={(e)=>setBiography(e.target.value)} />
                      <label >Your Social Media links</label>
                      <label htmlFor='facebook-link' >Your facebook</label>  
                      <input type="text" id='facebook-link' placeholder={user.facebook}
                      onChange={(e)=>setFacebook(e.target.value)}/>
                      <label htmlFor='twitter-link' >Your twitter</label>  
                      <input type="text" id='twitter-link' placeholder={user.twitter}
                      onChange={(e)=>setTwitter(e.target.value)}/>
                      <label htmlFor='web-link' >Your website if available</label>  
                      <input type="text" id='web-link' placeholder={user.web}
                      onChange={(e)=>setWeb(e.target.value)}/>
                    <button className="settingsSubmit" type='submit'>Update</button>
                    {error && <span style={{ color: 'red' }}>{error}</span>}
                    {success && <span style={{ color: 'green' }}>User updated successfully!</span>}
                </form>
            </div>
            <UserBio />
        </div></>
    );
}

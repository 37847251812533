import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../TopBarClient/download.png';
import menu from '../TopBarClient/menu.png';
import "./topbarclient.css";

export default function TopBarclient() {
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleListItemClick = () => {
    setCheckboxChecked(false); // Disable the checkbox when a list item is clicked
  };
   const handleClick =()=>{
        window.scrollTo(400, 400);
    }
  const handleBothClicks = () => {
  handleListItemClick();
  handleClick();
};

  return (
    <header className="topl">
      <a href="/" className="logo">
        THE STUDENTS' BLOG
      </a>

      <input type="checkbox" id="menu-bar" checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />
      <label htmlFor="menu-bar">
        <img src={menu} alt="" style={{ height: '30px' }} />
      </label>

      <nav className="navbar">
        <ul>
          <li>
            {' '}
            <Link to="/" className="link" onClick={handleListItemClick}>
              HOME
            </Link>{' '}
          </li>
          <li>
            {' '}
            <Link to="/about" className="link" onClick={handleListItemClick}>
              ABOUT
            </Link>{' '}
          </li>
          
           <li>
            <a
              href="https://bubuya-gpt.vercel.app/"
              target="_blank"
              rel="opener noreferrer"
            >
              AI
            </a>
          </li>
          <li>
            {' '}
            <Link to="/" className="link" id="submenu">
              ARTICLES <img src={img} alt="" className="scroll" />
            </Link>
             <ul className="submenu">
              <div className="articles-container">
                <li>
                  <Link
                    to={
                      "/?categories=Jobs Opportunities,Scholarships,Career Development"
                    }
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    Academic and Career Development
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={
                      "/?categories=Leadership,Peace,Human Rights,Woman Empowerment"
                    }
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    Personal Growth and Empowerment
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to={"/?categories=Technology and IT"} className="link" onClick={handleListItemClick}>
                    Technology and Innovation
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={"/?categories=Personal Growth,Productivity"}
                    className="link"
                   onClick={handleBothClicks} 
                  >
                    Productivity and Self-Improvement
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link
                    to={
                      "/?categories=Entrepreneurship,Start-up advice,Business development,Entrepreneurial mindset"
                    }
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    Entrepreneurship
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/?categories=Life Style,Sports,Events"}
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    Lifestyle and Entertainment
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={
                      "/?categories=Online courses,Digital skills training,Business development,E-learning platforms"
                    }
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    MOOCs and Digital Literacy
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={
                      "/?categories=Books Recommendations,Science and Technology"
                    }
                    className="link"
                   onClick={handleBothClicks} 
                  >
                    Knowlegde and learning
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link
                    to={
                      "/?categories=Health and reproduction,Mental health,Healthy lifestyle habits"
                    }
                    className="link"
                   onClick={handleBothClicks} 
                  >
                    Health and Wellness
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link
                    to={
                      "/?categories=Finance,Cryptocurrencies,Environment and sustainability,AI and machine learning"
                    }
                    className="link"
                    onClick={handleBothClicks} 
                  >
                    Other topics
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link
                    to={
                      "/?categories=Computer science,Medicine,Institute for Health,Economics,Management & accountability,ISCOBA,LAWS"
                    }
                    className="link"
                   onClick={handleBothClicks} 
                  >
                    Faculties
                  </Link>{" "}
                </li>
              </div>
            </ul>
          </li>
          <li>
            {' '}
            <Link to="/bloggers" onClick={handleListItemClick}>
              BLOGGERS
            </Link>{' '}
          </li>
          <li>
            {' '}
            <Link to="/login" onClick={handleListItemClick}>
              LOGIN
            </Link>{' '}
          </li>
        </ul>
      </nav>
    </header>
  );
}

import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../../config';
import { Link } from 'react-router-dom';
import './postsadmins.css';

function PostsAdmins() {
    const handleClick =()=>{
        window.scrollTo(0,0);
      }
  const [postsadmins, setPostsAdmins] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch all users when the component mounts
    axiosInstance.get('/postsadmins') // Adjust the URL to match your backend API route
      .then((response) => {
        setPostsAdmins(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message); // Set the error message in the state
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }
  return(
   <>
    {
        postsadmins.map((postsadmin)=>(
          <div className="postadmin" key={postsadmin._id}>
<div className="containeradmin">
      {postsadmin.photo && (
        <img className="postImgadmin" src={postsadmin.photo} alt="" />
      )}</div>


      <div className="postInfoadmin">
        
          <Link to={`/?categories=${postsadmin.categories}`} className="link">
            <span className="postCatadmin">{postsadmin.categories}</span>
          </Link>
        
        
        <Link to={`/postsadmins/${postsadmin._id}`} className="link">
          <span className="postTitleadmin" onClick={handleClick}>{postsadmin.title}</span>
        </Link>
        <hr />
        
        <span className="postDateadmin">
          {new Date(postsadmin.createdAt).toDateString()}
        </span>
        
      <Link to={`/postsadmins/${postsadmin._id}`} className="link">
        <p className="postDescadmin" onClick={handleClick}>
        <div dangerouslySetInnerHTML=
        {{ __html: `${postsadmin.desc}` }}></div></p>
      </Link>
      
    </div>

          </div>
        ))
      }
   </>
  )}

  export default PostsAdmins;
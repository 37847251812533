import { Link } from 'react-router-dom';
import './post.css';


export default function Post({post}) {
  const handleClick =()=>{
    window.scrollTo(0,0)
  }



  return (
    <div className="post" key={post._id}>
     <div className="container">
        <div className="postImg">
          {post.photo && (
            <img className="postImg" src={post.photo} alt="" />
          )}
        </div>
        <div className="postInfo">
          <div className="postCats">
          <Link to={`/?categories=${post.categories}`} className="link">
            <span className="postCat">{post.categories}</span>
          </Link>
          <Link to={`/posts/${post._id}`} className="link">
            <span className="postTitle" onClick={handleClick}>{post.title}</span>
          </Link>
          </div>
          
          
          <hr />
          <span className="postDate">{new Date(post.createdAt).toDateString()}</span>
          <Link to={`/posts/${post._id}`} className="link"> <p className="postDesc"><div dangerouslySetInnerHTML=
                {{ __html: `${post.desc}` }}></div></p></Link>
        </div>
      </div>
      
    </div>
   
    
  )}

import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About Us</h3>
          <p >Hyphen Tech: We are a Tech startup born at Ngozi University with different branches as Hyphen Tech Design, Hyphen Tech Education and more</p>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>You can contact us to have your account here and contribute in the creation of content</p>
         <p class="blue-text">Email: <a class="blue-text" href="mailto:hyphentechinfo@gmail.com">hyphentechinfo@gmail.com</a></p>
         <p class="blue-text">Phone: <a class="blue-text" href="tel:+25762153272">+(257) 62 15 32 72</a></p>

        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="/">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="/">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="/">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} <a class="blue-text" href="https://hyphentech.netlify.app/" target='_blanck'>Hyphen Tech</a>. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBy4iFpZJpNCxKtJPMg3gWSW7CAYdEgk0s",
  authDomain: "ulpoad-66fb7.firebaseapp.com",
  projectId: "ulpoad-66fb7",
  storageBucket: "ulpoad-66fb7.appspot.com",
  messagingSenderId: "38397169552",
  appId: "1:38397169552:web:62f1a0cb215345c754ad15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
import { useContext } from 'react'
import '../userBio/userBio.css'
import { Context } from '../../../context/Context'
import facebook from '../../../icons/facebook.svg'
import twitterx from '../../../icons/twitterx.svg'
import website from '../../../icons/website.png'


export default function UserBio() {

    const{user}=useContext(Context);

  return (
    <div className="userBio">
        <div className="sidebarItem">
            <span className="sidebarTitle">ABOUT {user.username.toUpperCase()}</span>
            <img className='sidebarImg' src={user.profilepic} alt="the profile pic" />
        </div>
        <p>{user.biography}</p>
        <div className="sidebarItem">
            <span className="sidebarTitle">FOLLOW US</span>
        </div>
        <div className="sidebarSocial">
        <a href={`http://${user.facebook}`} target="_blank" rel="noopener noreferrer"> <img src={facebook} alt="" className='icons' /></a>
        <a href={`http://${user.twitter}`} target="_blank" rel="noopener noreferrer"><img src={twitterx} alt="" className='icons' /></a>
        <a href={`http://${user.web}`} target="_blank" rel="noopener noreferrer"> <img src={website} alt="" className='icons' /></a>   
        </div>
    </div>

  )
}


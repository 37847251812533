// import axios from "axios";
import React, { useEffect, useState } from "react";
import '../Menu/menu.css';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../config';


export default function Menu() {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/posts?page=${page}&limit=10`);
        const { posts, totalPosts } = response.data;
        setPosts(posts);
        setTotalPages(Math.ceil(totalPosts / 10)); // Assuming 10 posts per page
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

    const handleClick=()=>{
        window.scrollTo(0,0)
    }


  return (
    <div className="menu">
    <h3 className='titleMenu'>Other posts you may like</h3>
    {posts.map((post) => (
      <div className="post" key={post._id}>
        <div className="cardMenu">
        <img src={post.photo} alt="" className='img'/>
        <p> <Link to={`/?categories=${post.categories}`} className="link">
            <span className="postCat">{post.categories}</span>
          </Link></p>
        <Link to={`/posts/${post._id}`} className='link'>
        <h2 className='titleMenu' onClick={handleClick}>{post.title}</h2></Link>
        <label>Posted by:{post.username}</label>
        <p className='postMenu'><div dangerouslySetInnerHTML=
                {{ __html: `${post.desc}` }}></div></p>
        <Link to={`/posts/${post._id}`} className='link' >
          <button className='buttonMenu' onClick={handleClick}>Read More</button></Link>
      </div>
      </div>
    ))}
    {/* Pagination controls */}
    <div className="pagination">
        {page > 1 && (
          <button onClick={() => handlePageChange(page - 1)}>Previous</button>
        )}

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === page ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}

        {page < totalPages && (
          <button onClick={() => handlePageChange(page + 1)}>Next</button>
        )}
      </div>
    </div>
  )
}

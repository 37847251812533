import Home from "./components/Pages/Home/Home";
import TopBar from "./components/TopBar/TopBar";
import Single from "./components/Pages/Single/Single";
import Write from "./components/Pages/Write/Write";
import Settings from "./components/Pages/Settings/Settings";
import Login from "./components/Pages/Login/Login";
import Register from "./components/Pages/Register/Register";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { useContext } from "react";
import { Context} from "./context/Context";
import { Context1 } from "./context/Context1";
import About from "./components/Pages/About/About"
import Footer from "./components/footer/Footer";
import RegisterAdmin from "./components/Admin/registerAdmin/RegisterAdmin";
import LoginAdmin from "./components/Admin/loginAdmin/LoginAdmin";
import UserList from "./components/Pages/userList/UserList";
import SingleUser from "./components/Pages/singleUser/SingleUser";
import WriteAdmin from "./components/Admin/writeAdmin/WriteAdmin";
import SingleAdmin from "./components/Admin/singleAdmin/SingleAdmin";
import TopBarclient from "./components/Topbars/TopBarClient/TopBarclient";

// dark mode 

import { Toggle } from './components/toggle/Toggle';
import { useDarkMode } from './styles/useDarkMode';
import { GlobalStyles, lightTheme, darkTheme } from './styles/globalStyles';
import { ThemeProvider } from 'styled-components';



function App() {
  
// the variable for the dark mode
const [ theme, toggleTheme ] = useDarkMode();
const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const {user} = useContext(Context);
  const {admin}=useContext(Context1);

  return (
    <Router>
     {(user||admin)?<TopBar/>:<TopBarclient/>}
     
     <ThemeProvider theme={themeMode}>
      <GlobalStyles />
        <Toggle theme={theme} toggleTheme={toggleTheme} />

      <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route  path="/register">
            {admin ?<Register/> : <Home/>}
          </Route>
          <Route  path="/login">
          {user ?<Home/> : <Login/>}
          </Route>
          <Route  path="/write">
          {user ? <Write/> : <Home/>}
          </Route>
          <Route  path="/settings">
          {user ?<Settings/> : <Home/>}
          </Route>
          <Route  path="/about">
          <About />
          </Route>
          <Route  path="/posts/:postId">
            <Single/>
          </Route>
          
          <Route  path="/bloggers">
          <UserList />
          </Route>
          <Route path="/user/:id">
            <SingleUser />
          </Route>
          <Route  path="/registerAdmin">
           <RegisterAdmin />
          </Route>
          <Route  path="/1q2w3e4r">
          {admin ? <Home/>:<LoginAdmin/>}
          </Route>
          <Route  path="/writeadmin">
          {admin && <WriteAdmin/>}
          </Route>
          <Route  path="/postsadmins/:postadminId">
            <SingleAdmin />
          </Route>
          <Route  path="*">
            <Home/>
          </Route>
      </Switch>
      </ThemeProvider>
      <br />
      <Footer />
   </Router>
  );
}

export default App;

import React from 'react';
import './about.css';
import { useState } from 'react';
import {Helmet}  from 'react-helmet';

const About = () => {
  const [partnerLogoActive, setPartnerLogoActive] = useState(false);

  const handlePartnerLogoClick = () => {
    setPartnerLogoActive(!partnerLogoActive);
  };
  return (
    <>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>About Hyphen Tech Blog</title>
        <meta name="description" content="This is the home page of my website." />
      </Helmet>
      <div className="about">
        <div className="about-header">
          <h1>About Us</h1>
        </div>
        <div className="about-content">
          <div className="about-section1">
            <h2>Vision</h2>
            <p>To provide valuable and insightful content that educates, inspires, and empowers readers in various areas of interest.</p>
          </div>
          <div className="about-section2">
            <h2>Mission</h2>
            <p>Our mission is to provide informative and engaging content on various topics that help readers improve their knowledge, skills, and well-being. We deliver high-quality articles, tips, and resources.</p>
          </div>
          <div className="about-section3">
            <h2>Objectives</h2>
            <p>Our blog informs and educates readers on various topics, inspires and motivates them to pursue their passions and goals, empowers and supports them with practical tips and resources, and fosters a community where they can connect and learn from each other.</p>
          </div>
          <div className="about-section4">
            <h2>Storylines</h2>
            <p>In a bustling college campus, two classmates named Elie Bubuya and Dieudonné Abanabimana found themselves sitting next to each other in a lecture hall. Both passionate about technology and entrepreneurship, they quickly struck up a conversation and realized they shared a common vision.</p>
          </div>
          {/*<section className="partners-section">
            <h2>Our Partners</h2>
            <div className={`partner-logo ${partnerLogoActive ? 'active' : ''}`} onClick={handlePartnerLogoClick}>
              <img src="/partner-logo1-gray.png" alt="Partner 1 Logo" />
            </div>
            <div className={`partner-logo ${partnerLogoActive ? 'active' : ''}`} onClick={handlePartnerLogoClick}>
              <img src="/partner-logo2-gray.png" alt="Partner 2 Logo" />
            </div>
            <div className={`partner-logo ${partnerLogoActive ? 'active' : ''}`} onClick={handlePartnerLogoClick}>
              <img src="/partner-logo3-gray.png" alt="Partner 3 Logo" />
            </div>
          </section>*/}
        </div>
      </div>
    </>
  );
};

export default About;

const Reducer1 =(state, action)=>{
    switch(action.type){
    case "LOGINADMIN_START":
        return {
            admin:null,
            isFetching:true,
            error:false
        };
        case "LOGINADMIN_SUCCESS":
        return {
            admin:action.payload,
            isFetching:false,
            error:false
        };
        case "LOGINADMIN_FAILURE":
        return {
            admin:null,
            isFetching:false,
            error:true
        };

        case "LOGOUTADMIN":
            return {
                admin:null,
                isFetching:false,
                error:false};
        default:
        return state;
}}

export default Reducer1;
import React, { useEffect, useState } from "react";
import "./posts.css";
import Post from "../Post/Post";
import { axiosInstance } from '../../config';
import { useLocation } from 'react-router-dom'


export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {search} =useLocation();

  const handleClick=()=>{
    window.scrollTo(360,360);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (search.includes("user=")) {
          // If the URL contains a user query parameter, fetch user-specific posts.
          response = await axiosInstance.get(`/posts${search}&page=${page}&limit=10`);
        }
        else if (search.includes("categories=")) {
          // If the URL contains a user query parameter, fetch user-specific posts.
          response = await axiosInstance.get(`/posts${search}&page=${page}&limit=10`);
        }
         else {
          // Fetch all posts if there's no user query parameter.
          response = await axiosInstance.get(`/posts?page=${page}&limit=10`);
        }
        const { posts, totalPosts } = response.data;
        setPosts(posts);
        setTotalPages(Math.ceil(totalPosts / 10)); // Assuming 3 posts per page
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, search]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="posts">
      {posts.map((p) => (
        <Post post={p} key={p._id} />
      ))}

      {/* Pagination controls */}
      <div className="pagination" onClick={handleClick}>
        {page > 1 && (
          <button onClick={() => handlePageChange(page - 1)}>Previous</button>
        )}

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === page ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}

        {page < totalPages && (
          <button onClick={() => handlePageChange(page + 1)}>Next</button>
        )}
      </div>
    </div>
  );
}

import './write.css';
import { useContext, useState } from 'react';
import {Context } from '../../../context/Context'
import { axiosInstance} from '../../../config';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { useLocation} from "react-router-dom";
import {storage} from "../../../Firebase";
import{ref,getDownloadURL,uploadBytes} from "firebase/storage"





export default function Write() {
  const state = useLocation().state;
    const [title, setTitle]=useState('');
    const[categories, setCategories]=useState([]);
    const [error, setError]=useState(false);
    const [file, setFile]=useState(null);
    const {user} = useContext(Context);
    const [value, setValue] = useState(state?.title || "");

    
    const handleSubmit =async (e)=>{
        e.preventDefault();
        const newPost ={
          username:user.username,
          title,
          desc:value,
          categories,
        };
        if (file) {
          const storageRef = ref(storage, `/images/${file.name}`);
          await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(storageRef);
          newPost.photo = imageUrl;
          }
          
          try{
              const res =await axiosInstance.post("/posts", newPost);
              window.location.replace(`/posts/${res.data._id}`);

          }catch(err){
            setError(true);
          }

        }
    

  return (
    <div className="write">
      {file && <img className='writeImg' src={URL.createObjectURL(file)}
         alt="" />}
        
        <form className="writeForm" onSubmit={handleSubmit}>
            <div className="writeFormGroup">
            <label htmlFor="fileInput">
                <i className="writeIcon fas fa-plus"></i>
            </label>
            <input type="file" id="fileInput"
             style={{display:"none"}} 
            onChange={e=>setFile(e.target.files[0])}/>
            <input className="writeInput"  
            type="text" placeholder='The title of your blog' 
            id="" autoFocus={true}
            onChange={e=>setTitle(e.target.value)}
            />
            </div>
            <div className="writeFormGroupQuill">
                  <ReactQuill 
                     theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Storytelling section..."
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['bold', 'italic', 'underline'],
                        ['link'],
                      ],
                    }}
                  />
           {error && <span style={{color:"red"}}>There might be error, please refresh the page</span>}
                </div>

  
          
  <select id="categorySelect" onChange={(e)=>setCategories(e.target.value)}>
  <option value="-1">Select a Category</option>

  <optgroup label="1. Academic and Career Development">
    <option value="Jobs Opportunities">Jobs Opportunities</option>
    <option value="Scholarships">Scholarships</option>
    <option value="Career Development">Career Development</option>
  </optgroup>

  <optgroup label="2. Personal Growth and Empowerment">
    <option value="Leadership">Leadership</option>
    <option value="Peace">Peace</option>
    <option value="Human Rights">Human Rights</option>
    <option value="Woman Empowerment">Woman Empowerment</option>
  </optgroup>

  <optgroup label="3. Technology and Innovation">
    <option value="Technology and IT">Technology and IT</option>
  </optgroup>

  <optgroup label="4. Lifestyle and Entertainment">
    <option value="Life Style">Life Style</option>
    <option value="Sports">Sports</option>
    <option value="Events">Events</option>
  </optgroup>

  <optgroup label="5. Knowledge and Learning">
    <option value="Books Recommendations">Books Recommendations</option>
    <option value="Science and Technology">Science and Technology</option>
  </optgroup>

  <optgroup label="6. Productivity and Self-Improvement">
    <option value="Personal Growth">Personal Growth</option>
    <option value="Productivity">Productivity</option>
  </optgroup>

  <optgroup label="7. Entrepreneurship">
    <option value="Start-up advice">Start-up advice</option>
    <option value="Business development">Business development</option>
    <option value="Entrepreneurial mindset">Entrepreneurial mindset</option>
  </optgroup>

  <optgroup label="8. MOOCs and Digital Literacy">
    <option value="Online courses">Online courses</option>
    <option value="Digital skills training">Digital skills training</option>
    <option value="E-learning platforms">E-learning platforms</option>
  </optgroup>

  <optgroup label="9. Health and Wellness">
    <option value="Health and reproduction">Health and reproduction</option>
    <option value="Mental health">Mental health</option>
    <option value="Healthy lifestyle habits">Healthy lifestyle habits</option>
  </optgroup>

  <optgroup label="10. Other topics">
    <option value="Finance">Finance</option>
    <option value="Cryptocurrencies">Cryptocurrencies</option>
    <option value="Environment and sustainability">Environment and sustainability</option>
    <option value="AI and machine learning">AI and machine learning</option>
  </optgroup>

  <optgroup label="11. Faculties">
    <option value="Computer science">Computer science</option>
    <option value="Medecine">Medicine</option>
    <option value="Institute for Health">Institute for Health</option>
    <option value="Agronomy">Agronomy</option>
    <option value="Economics">Economics</option>
    <option value="Gestion & accountability">Management & accountability</option>
    <option value="Iscoba">Iscoba</option>
    <option value="Droit">Law</option>
  </optgroup>
</select>





            <button className="writeSubmit" type="submit">Publish</button>
        </form>
    </div>
  )
}

import Menu from '../../Menu/Menu'
import SingleAdminPost from '../singleAdminPost/SingleAdminPost'
import './singleAdmin.css'
import React from 'react'
import { useEffect, useState } from 'react'
import { axiosInstance } from '../../../config';

export default function SingleAdmin() {
  const [posts, setPosts] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axiosInstance.get('/posts/');
          setPosts(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    })
  return (
    <div className="single">
        <SingleAdminPost/>
        <Menu posts={posts} />
    </div>
  )
}

import React, { useEffect, useState } from "react";
import "./home.css";
import Header from "../../Header/Header";
import Posts from "../../Posts/Posts";
// import Sidebar from '../../Sidebar/Sidebar'
import { axiosInstance } from "../../../config";
import { useLocation } from "react-router-dom";
import PostsAdmins from "../../Admin/postsadmins/PostsAdmins";
import Categories from "../categories/Categories";
// import Newsletter from '../../newsletter/Newsletter'

import { Helmet } from "react-helmet";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axiosInstance.get("/posts" + search);
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog des étudiants - université de Ngozi</title>
        <meta
          name="Blog des étudiants - université de Ngozi"
          content="Blog des étudiants - université de Ngozi"
        />
      </Helmet>

      <Header />
      <PostsAdmins />
      <div className="home">
        <Posts posts={posts} />
        {/* <Sidebar /> */}
        <Categories />
      </div>
    </>
  );
}

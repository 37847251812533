import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../config";
import "./singleUser.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import facebook from "../../../icons/facebook.svg";
import twitterx from "../../../icons/twitterx.svg";
import website from "../../../icons/website.png";

import { Helmet } from "react-helmet";

function SingleUser({ match }) {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  useEffect(() => {
    // Fetch the single user when the component mounts
    axiosInstance
      .get(`/users/${path}`) // Adjust the URL to match your backend API route
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  }, [path]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${user.username} || Blog des étudiants - université de Ngozi`}</title>
        <meta
          name={user.username}
          content="Blog des étudiants - université de Ngozi"
        />
      </Helmet>

      <div className="user-list-container">
        <h2> About {user.username}</h2>
        <div className="user-item">
          <div className="user-avatar">
            <img src={user.profilepic} alt={`${user.username}'s profile`} />
          </div>
          <div className="user-details">
            <p>{user.username}</p>
            <p>{user.biography}</p>
            <br />
            <Link to={`/?user=${user.username}`} className="link">
              <button className="button">Read my blogs</button>
            </Link>
            <div className="sidebarSocial">
              <a
                href={`http://${user.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={facebook} alt="" className="icons" />
              </a>
              <a
                href={`http://${user.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterx} alt="" className="icons" />
              </a>
              <a
                href={`http://${user.web}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={website} alt="" className="icons" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleUser;

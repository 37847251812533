import { Link } from 'react-router-dom';
import './header.css';
import lake from './lake.jpg';

export default function Header() {
  return (
    <header className="header">
      <div className="headerContent">
        <h1 className="headerTitle">
          <Link to="/about" className="headerLink">
            UNIVERSITE DE NGOZI
          </Link>
        </h1>
        <p className="headerSubtitle">The students' blog</p>
      </div>
      <img className="headerImage" src={lake} alt="Lake" />
    </header>
  );
}

import { axiosInstance } from '../../../config';
import Menu from '../../Menu/Menu'
// import Sidebar from '../../Sidebar/Sidebar'
import SinglePost from '../../SinglePost/SinglePost'
import './single.css'
import { useState, useEffect } from 'react';

export default function Single() {
  
    const [posts, setPosts] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axiosInstance.get('/posts/');
          setPosts(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    })
  return (
    <div className="single">
        <SinglePost />
        {/* <Sidebar/> */}
        <Menu posts={posts} />
    </div>
  )
}

import { createContext, useEffect, useReducer } from "react";
import Reducer1 from "./Reducer1";

// Admin

const INITIAL_STATE ={
    admin:JSON.parse(localStorage.getItem("admin")) || null,
    isFetching:false,
    error:false,
};



export const Context1 =createContext(INITIAL_STATE);


export const ContextProvider1 =({children})=>{
    const [state, dispatch1]=useReducer(Reducer1, INITIAL_STATE);

    useEffect(()=>{
        localStorage.setItem("admin", JSON.stringify(state.admin));
    }, [state.admin])

    return(
        <Context1.Provider value={{
            admin:state.admin,
            isFetching:state.isFetching,
            error:state.error, 
            dispatch1,
        }}>
           {children}
        </Context1.Provider>
    )
}
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../config";
import "./userList.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function UserList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch all users when the component mounts
    axiosInstance
      .get("/users") // Adjust the URL to match your backend API route
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message); // Set the error message in the state
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bloggers</title>
        <meta
          name="les bloggers - université de Ngozi"
          content="les bloggers - université de Ngozi."
        />
      </Helmet>

      <div className="user-list-containeruser">
        <h2>User List</h2>
        <ul className="user-listuser">
          {users.map((user) => (
            <li key={user._id} className="user-itemuser">
              <Link className="link" to={`/user/${user._id}`}>
                <div className="user-avataruser">
                  <img
                    src={user.profilepic}
                    alt={`${user.username}'s profile`}
                  />
                </div>
                <div className="user-detailsuser">
                  <p className="user-usernameuser">{user.username}</p>
                  <br />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default UserList;

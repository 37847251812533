// import { Link } from 'react-router-dom'
import './loginAdmin.css'
import { useRef, useState } from 'react'
import {  Context1 } from '../../../context/Context1';
import { useContext } from 'react';
import { axiosInstance } from '../../../config';

export default function Login() {

  const adminRef =useRef();
  const passwordRef =useRef();
  const{dispatch1,isFetching}=useContext(Context1);
  const [error, setError]=useState(false);

  const handleSubmit =async  (e)=>{
    e.preventDefault();
    dispatch1({type:"LOGINADMIN_START"});
    try{
      const res= await axiosInstance.post("/authAdmin/loginAdmin",{
        admin:adminRef.current.value,
        password:passwordRef.current.value,
      })
      dispatch1({type:"LOGINADMIN_SUCCESS", payload:res.data})
    } catch(err){
      setError(true)
    }
  }
  


  return (
    <div className="login">
        <span className="loginTitle" style={{fontSize:"20px", fontWeight:'90px'}}> <b>Login</b></span>
        <form  className="loginForm" onSubmit={handleSubmit}>
            <label>admin dashboard</label>
            <input type="text"
             placeholder='enter your admin...'
             ref={adminRef}
              className='loginInput' 
              />
            <label>Password</label>
            <input type="password" 
            placeholder='enter your password...'
             className='loginInput'
             ref={passwordRef}/>
            <button className="loginButton" 
            type='submit'
            disabled={isFetching}
            >Login</button> 
            <span style={{color:"red", marginTop:"10px"}}>{error && <span>Wrong credentials, refresh the page and try again!</span>}</span>
        </form>
        {/* <button className="loginRegisterButton">
          <Link to='/registerAdmin' className='link'>Register</Link> 
        </button> */}
        
    </div>
  )
}


import './categories.css'; // Import your CSS file
import Newsletter from '../../newsletter/Newsletter';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const handleClick =()=>{
        window.scrollTo(360, 360);
    }
  return (
    
    
    <div className="sidebar">
      <Newsletter/>
      <br />
      <br />
      <br />
      
      <div class="category " >
      <Link to={'/?categories=Jobs Opportunities,Scholarships,Career Development'}>
        <h2 onClick={handleClick}>Academic and Career Development</h2></Link> 
            <ul onClick={handleClick}>
            <Link to={'/?categories=Jobs Opportunities'}>   <li>Jobs Opportunities</li> </Link> 
            <Link to={'/?categories=Scholarships'}>   <li>Scholarships</li></Link>
            <Link to={'/?categories=Career Development'}><li>Career Development</li></Link>
            </ul>
        </div>

        <div class="category">
        <Link to={'/?categories=Leadership,Peace,Human Rights,Woman Empowerment'}>
            <h2  onClick={handleClick}>Personal Growth and Empowerment</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Leadership'}><li>Leadership</li></Link>
            <Link to={'/?categories=Peace'}><li>Peace</li></Link>
            <Link to={'/?categories=Human Rights'}><li>Human Rights</li></Link>
            <Link to={'/?categories=Woman Empowerment'}><li>Woman Empowerment</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Technology and IT'}><h2 onClick={handleClick}>Technology and Innovation</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Technology and IT'}><li>Technology and IT</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Personal Growth,Productivity'}> 
        <h2 onClick={handleClick}>Productivity and Self-Improvement</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Personal Growth'}> <li>Personal Growth</li></Link>
            <Link to={'/?categories=Productivity'}> <li>Productivity</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Books Recommendations,Science and Technology'}>
            <h2 onClick={handleClick}>Knowledge and Learning</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Books Recommendations'}> <li>Books Recommendations</li></Link>
            <Link to={'/?categories=Science and Technology'}> <li>Science and Technology</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Life Style,Sports,Events'}>
            <h2 onClick={handleClick}>Lifestyle and Entertainment</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Life Style'}><li>Life Style</li></Link>
            <Link to={'/?categories=Sports'}> <li>Sports</li></Link>
            <Link to={'/?categories=Events'}><li>Events</li></Link>
            </ul>
        </div>

        <div class="category">
        <Link to={'/?categories=Entrepreneurship,Start-up advice,Business development,Entrepreneurial mindset'}>
            <h2 onClick={handleClick}>Entrepreneurship</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Entrepreneurship'}><li>Entrepreneurship</li></Link>
            <Link to={'/?categories=Start-up advice'}> <li>Start-up advice</li></Link>
            <Link to={'/?categories=Business development'}> <li>Business development</li></Link>
            <Link to={'/?categories=Entrepreneurial mindset'}> <li>Entrepreneurial mindset</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Online courses,Digital skills training,Business development,E-learning platforms'}> 
        <h2 onClick={handleClick}>MOOCs and Digital Literacy</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Online courses'}><li>Online courses</li></Link>
            <Link to={'/?categories=Digital skills training'}><li>Digital skills training</li></Link>
            <Link to={'/?categories=Business development'}> <li>Business development</li></Link>
            <Link to={'/?categories=E-learning platforms'}>  <li>E-learning platforms</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Health and reproduction,Mental health,Healthy lifestyle habits'}>
            <h2 onClick={handleClick}>Health and Wellness</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Health and reproduction'}> <li>Health and reproduction</li></Link>
            <Link to={'/?categories=Mental health'}><li>Mental health</li></Link>
            <Link to={'/?categories=Healthy lifestyle habits'}><li>Healthy lifestyle habits</li></Link>
               
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Finance,Cryptocurrencies,Environment and sustainability,AI and machine learning'}>
            <h2 onClick={handleClick}>Other topics</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Finance'}><li>Finance</li></Link>
            <Link to={'/?categories=Cryptocurrencies'}><li>Cryptocurrencies</li></Link>
            <Link to={'/?categories=Environment and sustainability'}> <li>Environment and sustainability</li></Link>
            <Link to={'/?categories=AI and machine learning'}><li>AI and machine learning</li></Link>
            </ul>
        </div>
        <div class="category">
        <Link to={'/?categories=Computer science,Medicine,Institute for Health,Economics,Management & accountability,ISCOBA,LAWS'}>
            <h2 onClick={handleClick}>Faculties</h2></Link>
            <ul onClick={handleClick}>
            <Link to={'/?categories=Computer science'}><li>Computer science</li></Link>
            <Link to={'/?categories=Medicine'}><li>Medicine</li></Link>
            <Link to={'/?categories=Institute for Health'}><li>Institute for Health</li></Link>
            <Link to={'/?categories=Economics'}><li>Economics</li></Link>
            <Link to={'/?categories=Management & accountability'}> <li>Management & accountability</li></Link>
            <Link to={'/?categories=ISCOBA'}><li>ISCOBA</li></Link>
            <Link to={'/?categories=LAWS'}> <li>LAWS</li></Link>
            </ul>
        </div>


    </div>
   
  );
};

export default Sidebar;
